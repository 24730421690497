<template>
  <div class="group">
    <TopNavBar />
    <Carousel :carousels="carousels" />
    <div class="designIdea_box">
      <Title title="设计理念" />
      <div class="designIdeas">
        <div v-for="(item, index) in designIdeas" :key="index" class="item">
          <img :src="item.imgUrl" alt="" />
          <div>{{ item.title }}</div>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="buildIdea_box">
        <Title title="建设理念" />
        <div class="buildIdea">
          <div class="left">
            <p>5度建设理念</p>
          </div>
          <div class="buildIdeas">
            <div v-for="(item, index) in buildIdeas" :key="index" class="item">
              {{ item.title }} <span>—</span> {{ item.title2 }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="direction_box">
        <Title title="建设方向" />
        <div class="directions">
          <div v-for="(item, index) in directions" :key="index" class="item">
            <div class="title" v-if="index % 2 === 1">
              <p>{{ item.title }}</p>
              <p>{{ item.title2 }}</p>
            </div>
            <div class="content" :class="index % 2 === 0 ? 'left' : 'right'">
              <p>{{ item.content }}</p>
              <p>{{ item.content2 }}</p>
            </div>
            <div class="title" v-if="index % 2 === 0">
              <p>{{ item.title }}</p>
              <p>{{ item.title2 }}</p>
            </div>
          </div>
          <div class="middle">4大建设方向</div>
        </div>
      </div>
    </div>

    <div class="box">
      <div class="framework_box">
        <Title title="架构图" />
        <div class="framework">
          <img src="@/assets/images/product/group/bg2.png" alt="" />
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
export default {
  name: "Group",
  data() {
    return {
      designIdeas: [
        {
          title: "综合全面支撑业务",
          imgUrl: require("@/assets/images/product/group/build1.png"),
        },
        {
          title: "应用架构层次清晰",
          imgUrl: require("@/assets/images/product/group/build2.png"),
        },
        {
          title: "可视集团全维管控",
          imgUrl: require("@/assets/images/product/group/build3.png"),
        },
        {
          title: "应用操作便捷智能",
          imgUrl: require("@/assets/images/product/group/build4.png"),
        },
        {
          title: "持续沉浸式交互应用",
          imgUrl: require("@/assets/images/product/group/build5.png"),
        },
      ],
      buildIdeas: [
        { title: "简化部门操作", title2: "智能减负提效" },
        { title: "基于数据管理", title2: "数据驱动决策" },
        { title: "融合多端应用", title2: "内整外接融合" },
        { title: "汇聚数据资源", title2: "打造数据资产" },
        { title: "集化管理运维", title2: "一屏一图一表" },
      ],
      directions: [
        {
          title: "业务",
          title2: "场景化",
          content: "数字化协调-业务全场景智能协同",
          content2: "（基础业务场景构建业务协同）",
        },
        {
          title: "宣传",
          title2: "阵地化",
          content: "数字化展览-集团文化全天候展示",
          content2: "（建立数字文化宣传阵地）",
        },
        {
          title: "决策",
          title2: "数据化",
          content: "数字化决策-集群数据可视化资产",
          content2: "（基于全维数据推动管理科学化）",
        },
        {
          title: "应用",
          title2: "智能化",
          content: "智能化应用-数字孪生可视化操作",
          content2: "（基于智能技术和算法提升应用操作）",
        },
      ],
      carousels: [
        {
          imgUrl: require("@/assets/images/carousels/12.png"),
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.group {
  .box {
    display: flex;
    justify-content: center;
  }
  .designIdea_box {
    margin-top: 85px;
    .designIdeas {
      margin-top: 60px;
      padding: 0 160px;
      display: flex;
      justify-content: space-between;
      .item {
        img {
          width: 130px;
        }
        > div {
          font-family: "AlibabaPuHuiTi-Regular";
          font-weight: 400;
          font-size: 25px;
          color: #333;
        }
      }
      .item:hover {
        transform: scale(1.2);
        > div {
          color: #006eff;
        }
      }
    }
  }

  .buildIdea_box {
    margin-top: 100px;
    width: 100vw;
    padding: 65px 0 60px;
    height: 675px;
    background: linear-gradient(153.366175381599deg, #ffffff 23%, #ddeaff 79%);
    .buildIdea {
      width: 1400px;
      margin: 70px auto 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        position: relative;
        background-image: url(~@/assets/images/product/group/bg1.png);
        background-size: cover;
        width: 600px;
        height: 550px;
        > p {
          position: absolute;
          font-family: "AlibabaPuHuiTi-Regular";
          font-weight: 400;
          font-size: 30px;
          color: #ffffff;
          text-align: left;
          line-height: 50px;
          top: 220px;
          left: 110px;
        }
      }
      .buildIdeas {
        width: 600px;
        height: 520px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .item {
          box-shadow: 0px 0px 5px #66666659;
          line-height: 80px;
          background-color: rgba(255, 255, 255, 1);
          box-sizing: border-box;
          border: 1px solid #3e64ef;
          border-radius: 2px;
          font-weight: 400;
          font-size: 25px;
          color: #333333;
          font-family: "AlibabaPuHuiTi-Regular";
        }
        .item:hover {
          font-size: 30px;
          color: #006eff;
        }
      }
    }
  }
  .direction_box {
    min-width: 1780px;
    margin-top: 105px;
    .directions {
      position: relative;
      margin-top: 30px;
      height: 678px;
      background-image: url(~@/assets/images/product/group/build_bg.png);
      background-repeat: no-repeat;
      background-position: center;
      .item {
        position: absolute;
        height: 150px;
        display: flex;
        align-items: center;
        .content {
          width: 490px;
          height: 150px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-family: "AlibabaPuHuiTi-Light";
          font-weight: 200;
          font-size: 25px;
          line-height: 50px;
          background-repeat: no-repeat;
        }
        .left {
          background-image: url(~@/assets/images/product/group/build_left.png);
          text-align: right;
          padding-right: 110px;
        }
        .right {
          background-image: url(~@/assets/images/product/group/build_right.png);
          text-align: left;
          padding-left: 110px;
        }
        .title {
          width: 170px;
          height: 170px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          p {
            line-height: 50px;
            font-family: "AlibabaPuHuiTi-Light";
            font-weight: 200;
            font-size: 30px;
          }
        }
      }
      .item:nth-child(1),
      .item:nth-child(3) {
        left: 0;
      }
      .item:nth-child(2),
      .item:nth-child(4) {
        right: 0;
      }
      .item:nth-child(1),
      .item:nth-child(2) {
        top: 75px;
      }
      .item:nth-child(3),
      .item:nth-child(4) {
        bottom: 75px;
      }
      .item:hover {
        .content {
          p {
            color: #006eff;
            font-size: 30px;
          }
        }
        .title {
          p {
            font-size: 35px;
            color: #006eff;
          }
        }
      }
      .middle {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-family: "AlibabaPuHuiTi-Regular";
        font-weight: 400;
        font-size: 30px;
        color: #ffffff;
        text-align: center;
        line-height: 50px;
      }
    }
  }
  .framework_box {
    margin: 105px 0 80px;
    .framework {
      margin-top: 70px;
      width: 1350px;
      height: 900px;
      background-color: #ffffff;
      box-shadow: 0px 0px 30px #eeeeeeb3;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 1050px;
        height: 800px;
      }
    }
  }
}
</style>
